import { useMutation } from "@graphcommerce/graphql";
import IconCross from "../icons/IconCross";
import { removeProductsFromWishlistDocument } from "../../graphql/wishlist/removeProductsFromWishlist.gql";
import { useLoading } from "../../context/loadingContext";
import { useWishlist } from "../../context/wishlistContext";
import { Trans } from "@lingui/react";

export default function RemoveFromWishlistBtn({ item_id }: { item_id: string }) {
  const [removeFromWishlist] = useMutation(removeProductsFromWishlistDocument);
  const { setLoading } = useLoading();
  const { handleRefetch } = useWishlist()
  const handleClick = () => {
    setLoading(true);
    removeFromWishlist({
      variables: {
        wishlistId: '0',
        wishlistItemsIds: [
          item_id
        ]
      }
    }).then(({ data }) => {
      if (!data?.removeProductsFromWishlist?.user_errors.length) {
        handleRefetch()
      } else {
        alert(!data?.removeProductsFromWishlist?.user_errors[0]?.message)
      }
    }).catch(err => alert(err.message)).finally(() => setLoading(false))
  }

  return (
    // <IconCross width={18} height={18} className="text-light-400 cursor-pointer"
    <span
      onClick={handleClick}
      className="text-light-400 cursor-pointer"
    >
      <Trans id="Product removal" />
    </span>
  )
}