import React from "react";
type SectionHeaderProps = React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement>

export default function SectionHeader({ children, className, ...props }: SectionHeaderProps) {
  return (
    <div className={`w-full 992:relative px-4 text-[#333] bg-light-10 992:py-2 992:border-b-[2px] border-solid border-b-primary-500 ${className}`} {...props}>
      {children}

      <span className="absolute inline-block w-0 h-0  bottom-0 right-0 bg-transparent border-t-[12px] border-r-[12px] border-b-[0] boder-solid border-t-transparent border-b-transparent border-r-primary-500" />
    </div>
  )
}