
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import Link from "next/link";
import { useState } from "react";
import { setSubscribeEmailToNewsletter } from "../../../api/subscribeEmailToNewsletter";
import CustomSnackbar, { CustomSnackbarType } from "../../customSnackbar";


import IconQuestionCircle from "../../icons/IconQuestionCircle";


const usefullLinks = [
  {
    title: <Trans id='Contact Ordme' />,
    href: "/pages/contact"
  },
  {
    title: <Trans id='Frequently asked questions' />,
    href: "/pages/faq"
  },
  {
    title: <Trans id='Privacy' />,
    href: "/pages/privacy"
  },
  {
    title: <Trans id='Payment' />,
    href: "/pages/payment"
  },
  {
    title: <Trans id='Rules' />,
    href: "/pages/rules"
  },
  {
    title: <Trans id="Delivery" />,
    href: "/pages/delivery"
  },
  {
    title: <Trans id='Jobs' />,
    href: "/pages/jobs"
  },
  {
    title: <Trans id='Product return conditions' />,
    href: "/pages/money-back"
  },
  {
    title: <Trans id='About Ordme' />,
    href: "/pages/about"
  },
  {
    title: <Trans id='Discount guide' />,
    href: "/pages/coupon"
  },
]

const Questions = [
  { id: 'coupon_collection', defaultText: "چطور می‌توانم بُن تخفیف جمع‌آوری کنم و شرایط استفاده از آن چگونه است؟" },
  { id: 'shipping_cost', defaultText: "هزینهٔ ارسال چقدر است و سفارشم چند روزه به دستم می‌رسد؟" },
  { id: 'return_policy', defaultText: "آیا می‌توانم کالای خریداری‌شده را عودت بدهم؟" },
];
export default function TopFooterSection() {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [severity, setSeverity] = useState<CustomSnackbarType['severity']>('success')

  const [customerEmail, setCustomerEmail] = useState('');
  const handelSubscribeEmail = async (email: string) => {
    setLoading(true);
    setSeverity('success');
    setMessage(i18n._('Please wait a moment ...'))
    const result = await setSubscribeEmailToNewsletter(email);
    setLoading(false);

    if (result?.errors) {
      setMessage(result?.errors[0]?.message as string);
      setSeverity('error');
      setShow(true);

    } else {
      setMessage(i18n._("Your email has been added successfully"))
      setShow(true)
      setSeverity('success')
    }
  }

  return (<>
    {show && <CustomSnackbar
      message={message}
      onClose={() => setShow(false)}
      severity={severity}
    />}
    <div className="flex justify-between gap-7 max-w-970 1170:max-w-1170 mx-auto pb-7">
      <div className="flex-1">
        <h3 className="text-[14px] font-bold text-white border-[2px] border-solid border-[#343434] border-x-0 border-t-0">
          <Trans id="Useful links" />
        </h3>

        <div className="pt-3 flex justify-between">
          <ul className="flex justify-between flex-wrap">
            {usefullLinks.map((item) => (
              <li key={item.title} className="w-[50%] pb-2">
                <Link href={item.href ?? "#"} className="text-[13px] transition-colors hover:text-white">
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex-1">
        <h3 className="text-sm font-bold text-white border-[2px] border-solid border-[#343434] border-x-0 border-t-0">
          <Trans id="Frequently asked questions" />
        </h3>

        <div className="pt-3 flex justify-between">
          <ul className="flex justify-between flex-wrap w-[330px]">
            {Questions.map((item) => (
              <li key={item.id} className="w-[100%] pb-2">
                <Link href="/pages/faq" className="text-[13px] transition-colors hover:text-white">
                  <IconQuestionCircle width={16} height={16} className="inline-block ml-1" />
                  <Trans id={item.id}>{item.defaultText}</Trans>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>


      <div className="flex-1">
        <h3 className="text-sm font-bold text-white border-[2px] border-solid border-[#343434] border-x-0 border-t-0">
          <Trans id="The latest content in your email" />
        </h3>

        <div className="pt-3 flex flex-col justify-between">
          <p className="text-[13px] w-[260px]"><Trans id="By subscribing to the Ordami newsletter, you will be informed about the latest discounts, special sales, scientific articles in the field of beauty and health, and many other contents." /></p>

          <div className="flex justify-between h-8 mt-5 rounded-sm items-center">
            <input type="email" className="h-full text-center  border border-solid border-default-100 p-[1px] text-[13px] border-l-0 outline-none shadow-none bg-default-200 flex-1"
              value={customerEmail}
              onChange={(e) => setCustomerEmail(e.target.value)}
              placeholder={i18n._('your email')}
            />
            <button disabled={loading} className=" bg-primary-500 text-white text-[13px] font-light px-2 h-8"
              onClick={() => handelSubscribeEmail(customerEmail)}
            >
              <Trans id="Subscribe!" />
            </button>
          </div>
          <p className={`w-full text-xs ${severity === 'error' ? 'text-red-600' : 'text-green-500'}`}>{message}</p>
        </div>
      </div>
    </div>
  </>

  )
}