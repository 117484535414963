import { Image } from "@graphcommerce/image";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import Link from "next/link";




const items = [
  {
    title: <Trans id='Main Page' />,
    href: "/"
  },
  {
    title: "آرایشی",
    href: "/makeup"
  },
  {
    title: "بهداشتی",
    href: "/sanitary"
  },
  {
    title: "عطر و ادکلن",
    href: "/fragrance"
  },
  {
    title: "وسایل و ابزارها",
    href: "makeup-tools"
  },
  {
    title: "آقایان",
    href: "/fragrance-men"
  },
  {
    title: <Trans id='Brands' />,
    href: "/brands"
  },
  {
    title: "مشاوره رایگان",
    href: "/#"
  },
  {
    title: <Trans id="Blog" />,
    href: "/blog"
  }

]

export default function MiddleFooterSection() {
  return (
    <div className="border-[1px] border-solid border-light-200/25 border-x-0">
      <div className="max-w-970 1170:max-w-1170 mx-auto flex  justify-between items-center">
        <ul className="flex items-center gap-2">
          {items.map((item) => (
            <li key={item.title}>
              <Link href={item.href ?? "#"} className="pl-4 text-sm text-[#b6b6b6] hover:text-light-10 transition duration-300 ease-in-out">
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <div >
          <Image src="/footer-logo-banks-hq.png" alt="logo-banks" width={220} height={52} sx={{
            height : "52px"
          }}/>
        </div>
      </div>
    </div>
  )
}