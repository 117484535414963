import { useQuery } from "@graphcommerce/graphql"
import { Image } from "@graphcommerce/image"
import { GetCertificatesDocument } from "../../graphql/certificates/Certificates.gql"




// eslint-disable-next-line import/no-default-export
export default function CertificatesShow() {

  const { data } = useQuery(GetCertificatesDocument)
  const certificates = data?.getCertificates;


  const showCertificate = (cert) => {

    if (cert?.script)
      return cert.script;
    if (cert?.image)
      return <Image width={130} height={130} src={cert?.image} />
    return null;
  }

  return (
    <>
      {certificates?.map(certificate =>
        <div className="w-32 h-32">
          {showCertificate(certificate)}
        </div>
      )}
    </>
  )
}