import React, { useEffect, useState } from "react";
import SectionHeader from "../SectionHeader";
import ClearIcon from '@mui/icons-material/Clear';


type RightModalProps = React.ClassAttributes<HTMLDivElement> & React.HTMLAttributes<HTMLDivElement> & {
  modalTitle: string | null
  open: boolean | null
  onClose?: () => void
  width?: string
  mAuto?: boolean
}

export default function RightModal({ modalTitle, children, open, onClose, className, ...props }: RightModalProps) {
  return <>
    <div
      style={open ? { right: '0px' } : { right: '-380px' }}
      className={`fixed animation top-0 bottom-0 w-[350px] bg-white  z-[99999999] overflow-auto ${className}`} {...props}>
      <SectionHeader className="font-bold text-sm flex flex-row justify-between items-center !relative !border-b-[2px] !py-2">
        {modalTitle}
        <button onClick={() => onClose && onClose()} type="button"><ClearIcon className='text-lg text-ordme-gray-200' /></button>

      </SectionHeader>
      {children}
    </div>
    {open ? (<div className="fixed top-0 bottom-0 right-0 left-0 bg-[#00000061] z-[9998] curser" onClick={() => onClose && onClose()}>
    </div>
    ) : null}
  </>
}

export function CenterModal({ modalTitle, children, open, onClose, className, mAuto, width = 'md:max-w-[700px]', ...props }: RightModalProps) {
  return (
    <>
      {open &&
        <div
          className={`fixed animation top-0 bottom-0 right-0 left-0 z-[999] ${className}`} {...props}>
          <div className="fixed top-0 bottom-0 right-0 left-0 bg-[#00000061] z-[9998] curser" onClick={() => onClose && onClose()}>
          </div>
          <div className={`relative flex items-center justify-center w-full h-full ${mAuto ? 'm-auto' : ''}`}>
            <div className={`bg-white shadow-md rounded-md p-4 w-full h-[90%] md:h-auto md:w-[95%] relative z-[9999999] ${width}`}>
              {children}
            </div>
          </div>
        </div>}
    </>
  )
}