import { Trans } from '@lingui/react'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect } from 'react'
import { MegaMenuContentQuery } from '../../graphql/MegaMenuContent.gql'
import { storeLogoQuery } from '../../graphql/storeLogo.gql'
import IconBxChevronUp from '../icons/IconBxChevronUp'
import IconBxPlus from '../icons/IconBxPlus'
import HeaderLogo from './HeaderLogo'
import MobileLoginBtns from './MobileLoginBtns'
import { useRouter } from 'next/router'

type Props = {} & MegaMenuContentQuery & storeLogoQuery

export default function BottomNavbar({ amMegaMenuTree, storeLogo }: Props) {
  const items = amMegaMenuTree?.items
  const { query } = useRouter()
  const param = query?.url?.[0]

  const baseServerUrl = process.env.NEXT_PUBLIC_SITE_URL

  function handleUrl(url?: string | null): string {
    if (!url) {
      return '/'
    }
    if (url.startsWith('http')) return url
    else return '/' + url
  }
  return (
    <div
      className='bg-white 992:bg-light-50 shadow-md 992:h-[50px] 992:w-full 992:block fixed  top-0 right-0 border-light !translate-x-[100%] 992:!translate-x-0 bottom-0 w-[16rem] 992:relative z-[99] md:z-99 overflow-y-scroll 992:overflow-y-visible transition'
      id='MobileMenu'
    >
      <div className='flex 992:items-center flex-col 992:flex-row  h-full max-w-970 1170:max-w-1170 mx-auto relative'>
        <nav>
          <ul className='992:flex flex-col max-992:items-start  992:flex-row 992items-start items-center h-14 text-[#555555] font-[500]'>
            <li className='flex bg-primary-500 justify-between 992:hidden py-3 px-4 items-center'>
              <MobileLoginBtns />
              <HeaderLogo white storeLogo={'/ordme.png'} />
            </li>
            {items ? (
              <>
                {items.map((item) => (
                  <li
                    key={item?.uid}
                    className={`${param == item?.url ? 'bg-light-170' : ''}
                    ${
                      item?.label_text_color ? `text-[${item?.label_text_color}] ` : ''
                    } 992:px-[14px] gap-2 transition-colors md:hover:bg-light-170 flex flex-col items-stretch 992:flex-row 992:items-center group  border 992:border-0 border-solid border-light-50 border-x-0 border-t-0`}
                    style={{
                      backgroundColor: item?.label_background_color ?? undefined,
                      color: item?.label_text_color ?? undefined,
                    }}
                  >
                    {item?.children && item.children.length && (
                      <span className='bg-light-150 justify-center items-center  text-light-200 w-8 hidden 992:flex 1170:hidden h-12'>
                        <IconBxPlus width={18} height={18} />
                      </span>
                    )}
                    <Link
                      href={handleUrl(item?.url)}
                      className='992:flex max-992:hidden 992:text-sm h-12 992:py-0 items-center gap-1 closeMenu'
                    >
                      {item?.icon && (
                        <Image src={baseServerUrl + item?.icon} width={27} height={27} alt='' />
                      )}
                      {item?.name}
                    </Link>
                    <Link
                      href={
                        item?.children && item.children.length
                          ? 'javascript:void(0)'
                          : handleUrl(item?.url)
                      }
                      className={`pl-2 992:hidden pr-5 text-base font-medium py-4 flex gap-1 flex-1 justify-start menuLink ${
                        item?.children && item.children.length ? '' : 'closeMenu'
                      } `}
                    >
                      {item?.icon && item?.icon != '' && (
                        <Image src={baseServerUrl + item?.icon} width={27} height={27} alt='' />
                      )}
                      {item?.name}
                      {item?.children && item.children.length && (
                        <IconBxChevronUp width={18} height={18} className='!rotate-180' />
                      )}
                    </Link>
                    {item?.children && item.children.length && (
                      <div className='992:absolute hidden bg-initial 992:opacity-0 992:group-hover:block 992:group-hover:opacity-100 right-0 left-0 max-w-970 1170:max-w-1170 top-[100%] p-0 mobileSubMenu'>
                        <div className='bg-[#eee] 992:bg-[#fefefe] 992:rounded-sm 992:shadow-sm  pr-10 992:p-4 py-3 border border-solid border-[#d7d7d7]'>
                          <Link
                            href={handleUrl(item?.url)}
                            className='font-semibold 992:hidden text-sm py-4 flex-1 justify-between closeMenu'
                          >
                            <Trans id='View all products in this category' />
                          </Link>
                          <div className='flex'>
                            <ul className='flex-1 flex flex-col 992:flex-row max-992:pr-3 h-fit justify-start'>
                              {item.children.map((subMenu) => (
                                <li
                                  className='pr-3 992:border-r border-r-light-170 first:pr-0 first:border-none h-fit'
                                  key={subMenu?.uid}
                                >
                                  <Link
                                    href={handleUrl(subMenu?.url)}
                                    className='font-semibold text-sm text-primary-500 max-992:pt-4 max-992:pb-3 inline-block closeMenu'
                                  >
                                    {item?.icon && (
                                      <Image
                                        src={baseServerUrl + item?.icon}
                                        width={27}
                                        height={27}
                                        alt=''
                                      />
                                    )}
                                    {subMenu?.name}
                                  </Link>
                                  <ul className='overflow-hidden flex 992:flex-wrap flex-col w-full gap-y-2.5 mt-2 992:grid' style={{gridTemplateColumns: `repeat(${subMenu?.column_count}, minmax(0, 1fr))`}}>
                                    {subMenu?.children &&
                                      subMenu.children.length &&
                                      subMenu.children.map((subSubMenu) => (
                                        <li
                                          key={subSubMenu?.uid}
                                          className='max-w-full max-992:pb-3 992:pl-[15px] 992:w-[180px]'
                                        >
                                          <Link
                                            href={handleUrl(subSubMenu?.url)}
                                            className='text-sm font-normal hover:text-primary-500 transition-colors closeMenu'
                                            title={subSubMenu?.name ?? ''}
                                          >
                                            {subSubMenu?.name}
                                          </Link>
                                        </li>
                                      ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                            {item.uid === 'Y3VzdG9tLW5vZGUtNg==' ? (
                              <div className='hidden 992:block'>
                                <Image
                                  src='/static-image/blog-image.jpg'
                                  width={272}
                                  height={150}
                                  alt={item.label ?? ''}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </>
            ) : null}
          </ul>
        </nav>
      </div>
      <Scripts />
    </div>
  )
}

function Scripts() {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const mobileSubMenus = document.querySelectorAll('.mobileSubMenu')!
      const menuLinks = document.querySelectorAll('.menuLink')!

      menuLinks.forEach((item) =>
        item.addEventListener('click', () => {
          if (item.nextElementSibling?.classList.contains('!block')) {
            item.nextElementSibling?.classList.remove('!block')
            item.querySelector('svg')?.classList.add('!rotate-180')
          } else {
            mobileSubMenus.forEach((i) => i.classList.remove('!block'))
            item.nextElementSibling?.classList.add('!block')
            item.querySelector('svg')?.classList.remove('!rotate-180')
          }
        }),
      )

      const closeMenu = document.querySelectorAll('.closeMenu')!
      const menuBackDrop = document.querySelector('#menuBackDrop')!
      const MobileMenu = document.querySelector('#MobileMenu')!
      closeMenu.forEach((item) => {
        item?.addEventListener('click', function (e) {
          menuBackDrop.classList.remove('!block')
          MobileMenu.classList.remove('!block')
          menuBackDrop.classList.remove('!opacity-100')
          MobileMenu.classList.remove('!translate-x-[0%]')
          MobileMenu.classList.add('!translate-x-[100%]')
        })
      })

      /*  openMobileMenu.addEventListener('click', () => {
         if (menuBackDrop && MobileMenu) {
           menuBackDrop.classList.add('!block');
           MobileMenu.classList.add('!block');
           menuBackDrop.classList.add('!opacity-100');
     
         }
       }) */
    }
  }, [])
  return null
}
