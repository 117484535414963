import { Hidden } from '@mui/material'
// eslint-disable-next-line import/no-cycle
import CartIcon from './CartIcon'
import CompareIcon from './ComparIcon'
import ProfileIcon from './ProfileIcon'
import SearchIcon from './SearchIcon'
import WishListIcon from './WishListIcon'
import { useRouter } from 'next/router'

// eslint-disable-next-line import/no-default-export
export default function ActionIcons() {
  const { pathname } = useRouter()
  return (
    <div className='flex flex-row justify-between gap-4 1170:gap-6 items-center'>
      <SearchIcon />
      {pathname != '/checkout/cart' && pathname != '/checkout/onePage' && <CartIcon />}
      <div className='max-992:hidden contents'>
        {pathname != '/wishlist' && <WishListIcon />}
        {pathname != '/product_compare' && <CompareIcon />}
      </div>
      <div className='hidden 992:block 1170:-mx-1 my-auto  border-dashed border-l-[1px] border-ordme-gray-400/70 h-6 ' />
      <ProfileIcon />
    </div>
  )
}
