import { WaitForQueries } from '@graphcommerce/ecommerce-ui'
import { PageOptions } from '@graphcommerce/framer-next-pages'
import { ApolloCartErrorAlert, useCartQuery } from '@graphcommerce/magento-cart'

import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { FullPageMessage, GetStaticProps } from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import ClearIcon from '@mui/icons-material/Clear'
import { CircularProgress } from '@mui/material'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
// eslint-disable-next-line import/no-cycle
import { LayoutOverlayProps } from '..'
import { ModalContext } from '../../context/context'
import { getCartDocument } from '../../graphql/checkout/getCart.gql'
import { graphqlSharedClient } from '../../lib/graphql/graphqlSsrClient'
import { Money } from '../Money'
import { RemoveItemFromCart } from '../removeItemFromCart/inedx'

type Props = Record<string, unknown>
type GetPageStaticProps = GetStaticProps<LayoutOverlayProps, Props>

function CartPage() {
  const cart = useCartQuery(getCartDocument, { returnPartialData: true, errorPolicy: 'all' })
  const { data, error } = cart
  const hasItems =
    (data?.cart?.items?.length ?? 0) > 0 &&
    typeof data?.cart?.prices?.grand_total?.value !== 'undefined'

  const useModal = useContext(ModalContext)

  const earnPoint = () => {
    if (cart.data?.cart?.prices?.mp_reward_segments)
      return cart.data?.cart?.prices?.mp_reward_segments?.filter(
        (item) => item?.code === 'mp_reward_earn',
      )[0]?.value
    return 0
  }

  return (
    <WaitForQueries
      waitFor={cart}
      fallback={
        <FullPageMessage icon={<CircularProgress />} title={<Trans id='Loading' />}>
          <Trans id='This may take a second' />
        </FullPageMessage>
      }
    >
      <div className='p-2 flex flex-col gap-6 '>
        {hasItems ? (
          <div className='flex flex-col gap-8'>
            {data?.cart?.items?.map((item, index) => (
              <div className='border-dashed border-b pb-2'>
                <div className='flex justify-end'>
                  <RemoveItemFromCart
                    {...item}
                    childern={<ClearIcon className='text-lg text-ordme-gray-200' />}
                  />
                </div>
                <div className='flex flex-row items-start justify-between ml-3' key={index}>
                  <div className='flex'>
                    {item?.product?.thumbnail && (
                      <Image
                        src={item?.product?.thumbnail?.url}
                        alt={item?.product?.name}
                        width={50}
                        height={50}
                      />
                    )}
                    <div className='flex flex-col items-start gap-2'>
                      <Link
                        href={`/p/${item?.product?.url_key}`}
                        className='text-sm hover:text-primary-500 transition-colors'
                      >
                        {item?.product.name}
                      </Link>
                      {item?.__typename == 'ConfigurableCartItem' ? (
                        <div className='text-ordme-gray-400 w-[90%] text-xs'>
                          {item.configurable_options &&
                            item.configurable_options.map((option) => (
                              <>
                                <span>
                                  {option?.option_label} :{option?.value_label}
                                </span>
                              </>
                            ))}
                        </div>
                      ) : null}

                      <span className='text-sm font-medium text-secondary-400'>
                        <Money value={item?.prices?.row_total?.value} />
                      </span>
                    </div>
                  </div>
                  <div className='flex justify-between'>
                    <div className='flex flex-col'>
                      <span className='flex'>{`${i18n._('count')} ${item?.quantity}`}</span>
                      {item?.product?.price_range?.minimum_price?.discount?.amount_off ? (
                        <div className='bg-ordme-red px-1 font-bold text-white text-[10px]'>
                          تخفیف
                        </div>
                      ) : (
                        <div className='bg-ordme-red px-1 font-bold text-white text-[10px]'/>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <ApolloCartErrorAlert error={error} />
            <div className='flex flex-row text-sm text-light-400 items-center  justify-between'>
              <p>
                <Trans id='Total' />:
                <span className='text-secondary-400 font-semibold'>
                  <Money {...data.cart?.prices?.grand_total} />
                </span>
              </p>
              <Link href='/checkout/cart'>
                <button
                  className='bg-secondary-400 text-white text-sm font-medium p-2 rounded-sm'
                  onClick={useModal.close}
                >
                  <Trans id='View shopping cart and payment' />
                </button>
              </Link>
              {/* <CartStartCheckout {...data?.cart} /> */}
            </div>
            <p className='text-primary-500 text-xs font-medium flex justify-center'>
              <Trans
                id='Finalize your payment to receive {count} bin discounts!'
                values={{
                  count: earnPoint() ?? 0,
                }}
              />
            </p>
          </div>
        ) : (
          <p className='text-sm text-slate-600'>کالایی به سبد خرید شما اضافه نشده است.</p>
        )}
      </div>
    </WaitForQueries>
  )
}

const pageOptions: PageOptions<LayoutOverlayProps> = {
  overlayGroup: 'cart',
  layoutProps: { variantMd: 'bottom', variantSm: 'bottom' },
}
CartPage.pageOptions = pageOptions

// eslint-disable-next-line import/no-default-export
export default CartPage

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const conf = client.query({ query: StoreConfigDocument })

  return {
    props: {
      apolloState: await conf.then(() => client.cache.extract()),
    },
  }
}
