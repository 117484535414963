
import { Trans } from "@lingui/react";
import { ClassAttributes, SelectHTMLAttributes } from "react";

type SelectFieldElementProps = {
  name?: string
  label?: string
  fullwidth?: boolean
} & ClassAttributes<HTMLSelectElement> & SelectHTMLAttributes<HTMLSelectElement>

// eslint-disable-next-line import/no-default-export
export default function SelectField({ name, required, label, children, fullwidth, ...props }: SelectFieldElementProps) {
  return (
    <div className="w-full">
      <div className="flex gap-2 items-center">
        {label && <label className="ml-3">{label} {required && <span className="text-error-500">*</span>}</label>}
        <select {...props} className={`w-full text-center text-sm py-0.5 border border-solid border-gray-300 rounded-sm outline-0 shadow-none bg-white hover:border-gray-400 transition-colors ${fullwidth && 'w-full'}`}>
          {!props.defaultValue ? <option value="">
            <Trans id="Select..." />
          </option> : null}
          {children}
        </select>
      </div>
    </div>
  )
}