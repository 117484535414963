import { Trans } from '@lingui/react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Button } from '@mui/material'
import Link from 'next/link'
import { useContext } from 'react'
import { ModalContext } from '../../context/context'
import useCustomerData from '../../customHooks/useCustomerData'
import AuthForms, { FormType } from '../AuthForms'
import { SignOutForm } from '../AuthForms/SignOutForm'
import IconLogin from '../icons/IconLogin'
import IconPhone from '../icons/IconPhone'

// eslint-disable-next-line import/no-default-export
export default function TopNavbar() {
  const useModal = useContext(ModalContext)

  const { customerIsLogin, customerData } = useCustomerData()

  const firstname = customerData?.firstname ?? ''

  return (
    <div className='-mt-1 hidden bg-light-50 font-medium text-[#555555] text-[13px] fixed w-full shadow-sm h-7 992:block  top-1 right-0 left-0 text-center z-[120]'>
      <div className='flex flex-row justify-between h-full items-center 1170:max-w-1170 max-w-970 mx-auto'>
        <div className='flex flex-row items-center gap-5'>
          {customerIsLogin() ? (
            <div className='flex items-center gap-1 '>
              <Trans id='Hi {firstname}, You’re now logged in!' values={{ firstname }} />

              <Link
                href='/account'
                className='text-primary-500 transition hover:text-black no-underline'
              >
                <Trans id='Account' />
              </Link>
              <span> - </span>
              <SignOutForm>
                <Button
                  variant='text'
                  type='submit'
                  className='text-primary-500 transition hover:text-black no-underline !px-0 !bg-transparent !max-w-fit !min-w-fit'
                >
                  <Trans id='Sign out' />
                </Button>
              </SignOutForm>
            </div>
          ) : (
            <>
              <div className='flex flex-row items-center gap-1'>
                <IconLogin width={18} height={18} />

                <button
                  className='text-primary-500 transition hover:text-black no-underline'
                  onClick={() =>
                    useModal.set &&
                    useModal?.set({
                      content: <AuthForms activeForm={FormType.login} />,
                      open: true,
                      title: '',
                      position: 'center',
                      width: 'max-w-sm',
                    })
                  }
                >
                  <Trans id='Sign in' />
                </button>
                <span> | </span>
                <button
                  className='text-primary-500 transition hover:text-black no-underline'
                  onClick={() =>
                    useModal.set &&
                    useModal?.set({
                      content: <AuthForms activeForm={FormType.signup} />,
                      open: true,
                      title: '',
                      position: 'center',
                      width: 'max-w-sm',
                    })
                  }
                >
                  <Trans id='Register' />
                </button>
              </div>

              <div className='flex flex-row items-center gap-1'>
                <HelpOutlineIcon sx={{ fontSize: '20px' }} />

                <Link href='/pages/faq' className='transition hover:text-black no-underline'>
                  <Trans id='Help' />
                </Link>
              </div>
            </>
          )}
        </div>

        <div className='flex flex-row items-center gap-5'>
          <div className='flex flex-row items-center gap-1 text-default-600'>
            <Link href='/pages/contact' className='transition hover:text-black no-underline'>
              (<Trans id='Answering hours: non-holiday days, from 8 to 24'/>)
            </Link>
          </div>

          <div className='flex flex-row items-center gap-1'>
            <Link
              href='/pages/contact'
              className='text-default-400  transition hover:text-black no-underline flex items-center gap-2'
            >
              <IconPhone width={12} height={15} className='-mt-2' />
              <span>۰۲۱۹۱۳۰۰۳۱۲</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
