import { i18n } from "@lingui/core";
import Image from "next/image";
import Link from "next/link";
import { storeLogoQuery } from "../../graphql/storeLogo.gql";

// eslint-disable-next-line import/no-default-export

type HeaderLogoProps = { white?: boolean } & storeLogoQuery;
// eslint-disable-next-line import/no-default-export
export default function HeaderLogo({ white = false, storeLogo }: HeaderLogoProps) {
  return (
    <>
      <div className="flex ltr flex-row justify-start items-center h-8 992:h-[70px] w-[68px] 992:w-[162px]">
        <Link href="/">
          <Image width={162} height={69} src={storeLogo as string}
          style={white?{
          filter:'grayscale(100%) brightness(400%)'
        }:{}}
        alt={i18n._("Ordami specialized reference for cosmetics, health and wellness products.")} className='object-contain' />
        </Link>
      </div>
    </>
  );
}

