function IconLogout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 0.675 0.675'
      fill='currentColor'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0.346 0c0.106 0 0.204 0.047 0.27 0.126a0.022 0.022 0 0 1 -0.003 0.031 0.023 0.023 0 0 1 -0.032 -0.003 0.302 0.302 0 0 0 -0.234 -0.11c-0.166 0 -0.3 0.131 -0.3 0.293s0.134 0.293 0.3 0.293c0.094 0 0.18 -0.042 0.237 -0.113a0.023 0.023 0 0 1 0.032 -0.004 0.022 0.022 0 0 1 0.004 0.031C0.553 0.627 0.453 0.675 0.346 0.675 0.155 0.675 0 0.524 0 0.338S0.155 0 0.346 0m0.231 0.242 0.091 0.091c0.009 0.009 0.009 0.023 0 0.032l-0.089 0.089a0.022 0.022 0 0 1 -0.032 0 0.022 0.022 0 0 1 0 -0.032l0.052 -0.052H0.252a0.022 0.022 0 0 1 -0.023 -0.022c0 -0.012 0.01 -0.022 0.023 -0.022h0.347l-0.053 -0.053a0.022 0.022 0 0 1 0 -0.032 0.022 0.022 0 0 1 0.032 0'
      />
    </svg>
  )
}

export default IconLogout
