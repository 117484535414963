import { useApolloClient } from '@graphcommerce/graphql'
import { SignOutFormDocument } from '@graphcommerce/magento-customer/components/SignOutForm/SignOutForm.gql'
import { useFormGqlMutation } from '@graphcommerce/react-hook-form'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useLoading } from '../../context/loadingContext'

export function SignOutForm({ children}: { children: React.ReactNode;}) {
  const router = useRouter()
  const client = useApolloClient()
  const { setLoading } = useLoading()
  const { handleSubmit } = useFormGqlMutation(
    SignOutFormDocument,
    {
      onComplete: async () => {
        setLoading(false)
        await client.clearStore()
        await router.push('/')
        router.reload()
      },
    },
    { errorPolicy: 'all' },
  )
  const submitHandler = handleSubmit(() => {})
  return (
    <form
      onSubmit={async (e) => {
        setLoading(true)
        await submitHandler(e)
      }}
      noValidate
    >
      {children}
    </form>
  )
}
