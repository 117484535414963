// icon:user_male | System UIcons https://systemuicons.com/ | Corey Ginnivan
import * as React from "react";

function IconUser_male(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="512px"
      width="512px"
      {...props}
    >
      <path d="M202.4,201.7L202.4,201.7L202.4,201.7z" />
      <path d="M363.3,363.9c-12.9-4.6-31.4-6.2-43.2-8.8c-6.8-1.5-16.7-5.3-20-9.2c-3.3-4-1.3-40.9-1.3-40.9s6.1-9.6,9.4-18
		s6.9-31.4,6.9-31.4s6.8,0,9.2-11.9c2.6-13,6.6-18.4,6.1-28.1c-0.5-9-5.2-9.5-5.7-9.5l0,0c0,0,4.9-13.6,5.6-42.4
		C331.1,129.6,305,96,256,96s-75,33.5-74.3,67.6c0.6,28.7,5.6,42.4,5.6,42.4l0,0c-0.5,0-5.2,0.5-5.7,9.5c-0.5,9.7,3.6,14.9,6.1,27.9
		c2.4,11.9,9.2,12,9.2,12s3.6,23.1,6.9,31.5c3.3,8.5,9.4,18,9.4,18s2,36.9-1.3,40.9c-3.3,4-13.2,7.7-20,9.2
		c-11.9,2.6-30.3,4.3-43.2,8.9C135.8,368.5,96,384,96,416h160h160C416,384,376.2,368.5,363.3,363.9z M256,400H118.7
		c2-3,4.7-5.1,8.2-7.6c7-5.1,16.1-9.8,27.1-13.6c6.8-2.4,16.7-4,25.4-5.3c5.7-0.9,11.1-1.7,15.9-2.8c3.4-0.8,20.8-5,28.8-14.6
		c4.5-5.4,5.8-12.7,5.6-32.3c-0.1-10-0.6-19.3-0.6-19.7l-0.2-4.2l-2.3-3.5c-1.5-2.3-5.8-9.5-8-15.3c-1.8-4.7-4.6-19.2-6-28.1
		c0,0,0.4,1-0.5-3.7c-0.9-4.7-8.4-4.3-9.4-8c-0.9-3.6-1.8-6.9-4.3-18.2c-2.5-11.3,2.8-11.2,3.9-16.2c0.6-3.1,0-5.7,0-5.8l0,0
		c-0.3-1-4.1-13.4-4.7-37.7c-0.3-13.2,4.6-25.6,13.8-34.9c10.6-10.8,26-16.5,44.5-16.5c19,0,34,5.7,44.6,16.5
		c9.2,9.3,14.1,21.7,13.8,34.9c-0.5,24.2-4.3,36.6-4.7,37.7l0,0c0,0.1-0.6,1.7-0.4,5.2c0.2,5.4,6.8,5.5,4.3,16.8
		c-2.5,11.3-3.4,14.6-4.3,18.2c-0.9,3.6-8.5,3.3-9.4,8c-0.9,4.7-0.5,3.7-0.5,3.7c-1.4,8.9-4.2,23.4-6,28.1c-2.3,5.8-6.6,13-8,15.3
		l-2.3,3.5l-0.2,4.2c0,0.4-0.5,9.7-0.6,19.7c-0.2,19.6,1.1,26.9,5.6,32.3c8,9.5,25.4,13.8,28.8,14.6c4.8,1.1,10.2,1.9,15.9,2.8
		c8.7,1.3,18.6,2.9,25.4,5.3c11,3.9,20.2,8.6,27.1,13.7c3.5,2.5,6.2,4.6,8.2,7.6L256,400L256,400z"/>
    </svg>
  );
}

export default IconUser_male;
