import axios from "./axios";


export const subscribeEmailToNewsletterQuery = (email: string) => `mutation {
  subscribeEmailToNewsletter(
    email: "${email}"
  ) {
    status
  }
}`


export async function setSubscribeEmailToNewsletter(email: string) {
  return axios
    .post("/graphql", { query: subscribeEmailToNewsletterQuery(email) })
    .then((res) => res?.data)
    .catch((err) => {
      console.log('getStaticBlockData=====================>', err)
      return false
    });
}

