const IconCoins = (props) => (
  <svg width='1rem' height='1rem' fill='currentColor' viewBox='0 0 17.28 17.28' {...props}>
    <g>
      <path
        d='M11.105 6.046c0 0.794 -1.511 1.437 -3.376 1.437 -1.865 0 -3.376 -0.643 -3.376 -1.437 0 -0.794 1.511 -1.438 3.376 -1.438 1.864 0 3.376 0.644 3.376 1.438'
      />
      <path
        d='M4.353 15.842c0 0.794 1.511 1.438 3.376 1.438 1.864 0 3.376 -0.643 3.376 -1.438v-0.357a0.327 0.327 0 0 0 -0.147 -0.273 0.327 0.327 0 0 0 -0.309 -0.027s-1.056 0.652 -2.92 0.652c-1.865 0 -2.921 -0.652 -2.921 -0.652a0.328 0.328 0 0 0 -0.309 0.027 0.328 0.328 0 0 0 -0.147 0.273v0.357z'
      />
      <path
        d='M4.353 13.4c0 0.794 1.511 1.437 3.376 1.437 1.864 0 3.376 -0.643 3.376 -1.437v-0.357a0.327 0.327 0 0 0 -0.147 -0.273 0.327 0.327 0 0 0 -0.309 -0.027s-1.056 0.652 -2.92 0.652c-1.865 0 -2.921 -0.652 -2.921 -0.652a0.328 0.328 0 0 0 -0.309 0.027 0.328 0.328 0 0 0 -0.147 0.273v0.357z'
      />
      <path
        d='M4.353 10.958c0 0.794 1.511 1.437 3.376 1.437 1.864 0 3.376 -0.643 3.376 -1.437v-0.358a0.327 0.327 0 0 0 -0.147 -0.273 0.327 0.327 0 0 0 -0.309 -0.027s-1.056 0.653 -2.92 0.653c-1.865 0 -2.921 -0.653 -2.921 -0.653a0.328 0.328 0 0 0 -0.309 0.027 0.328 0.328 0 0 0 -0.147 0.273v0.358z'
      />
      <path
        d='M4.353 8.516c0 0.794 1.511 1.438 3.376 1.438 1.864 0 3.376 -0.643 3.376 -1.438V8.159a0.327 0.327 0 0 0 -0.147 -0.273 0.327 0.327 0 0 0 -0.309 -0.027s-1.056 0.652 -2.92 0.652c-1.865 0 -2.921 -0.652 -2.921 -0.652a0.328 0.328 0 0 0 -0.309 0.027 0.327 0.327 0 0 0 -0.147 0.273v0.357z'
      />
      <path
        d='M16.356 3.152c0 0.793 -1.511 1.437 -3.376 1.437 -1.865 0 -3.376 -0.643 -3.376 -1.437 0 -0.795 1.511 -1.438 3.376 -1.438 1.864 0 3.376 0.643 3.376 1.438'
      />
      <path
        d='M16.355 12.591v0.357c0 0.794 -1.511 1.437 -3.373 1.437 -0.438 0 -0.852 -0.035 -1.235 -0.099V12.842c0.36 0.061 0.772 0.103 1.235 0.103 1.862 0 2.919 -0.653 2.919 -0.653 0.1 -0.045 0.216 -0.033 0.309 0.025a0.332 0.332 0 0 1 0.145 0.273'
      />
      <path
        d='M16.355 10.15v0.358c0 0.794 -1.511 1.437 -3.373 1.437 -0.438 0 -0.852 -0.035 -1.235 -0.099v-1.447c0.36 0.061 0.772 0.103 1.235 0.103 1.862 0 2.919 -0.653 2.919 -0.653a0.331 0.331 0 0 1 0.309 0.028c0.09 0.061 0.145 0.161 0.145 0.273'
      />
      <path
        d='M16.355 7.708v0.357c0 0.794 -1.511 1.438 -3.373 1.438 -0.438 0 -0.852 -0.036 -1.235 -0.1v-1.447c0.36 0.061 0.772 0.103 1.235 0.103 1.862 0 2.919 -0.653 2.919 -0.653a0.33 0.33 0 0 1 0.309 0.03c0.09 0.06 0.145 0.163 0.145 0.273'
      />
      <path
        d='M16.355 5.264v0.357c0 0.794 -1.511 1.437 -3.373 1.437 -0.438 0 -0.852 -0.035 -1.235 -0.099v-0.913c0 -0.199 -0.042 -0.382 -0.122 -0.553 0.386 0.074 0.839 0.126 1.357 0.126 1.588 0 2.591 -0.476 2.851 -0.614 0.045 -0.025 0.068 -0.039 0.068 -0.039 0.1 -0.045 0.216 -0.032 0.309 0.026 0.042 0.029 0.077 0.065 0.1 0.109a0.324 0.324 0 0 1 0.045 0.165'
      />
      <path
        d='M7.675 1.438c0 0.794 -1.512 1.437 -3.376 1.437S0.924 2.231 0.924 1.438C0.924 0.643 2.435 0 4.299 0s3.376 0.643 3.376 1.438'
      />
      <path
        d='M3.709 11.205v1.444c-1.582 -0.119 -2.784 -0.707 -2.784 -1.414v-0.357c0 -0.109 0.055 -0.212 0.145 -0.274a0.331 0.331 0 0 1 0.309 -0.028S2.214 11.09 3.709 11.205'
      />
      <path
        d='M3.709 8.766v1.44c-1.582 -0.119 -2.784 -0.707 -2.784 -1.414v-0.357c0 -0.11 0.055 -0.213 0.145 -0.273 0.093 -0.059 0.209 -0.072 0.309 -0.026 0 0 0.836 0.515 2.331 0.631'
      />
      <path
        d='M3.709 6.322v1.443c-1.582 -0.119 -2.784 -0.708 -2.784 -1.415v-0.357a0.33 0.33 0 0 1 0.145 -0.273 0.329 0.329 0 0 1 0.309 -0.03s0.836 0.515 2.331 0.631'
      />
      <path
        d='M7.674 3.55v0.357c0 0.02 0 0.039 -0.003 0.058 -1.595 0.014 -3.196 0.479 -3.752 1.37 -1.685 -0.081 -2.994 -0.689 -2.994 -1.428v-0.357a0.331 0.331 0 0 1 0.145 -0.273c0.093 -0.061 0.209 -0.071 0.309 -0.026 0 0 1.058 0.652 2.923 0.652 1.862 0 2.919 -0.652 2.919 -0.652 0.055 -0.026 0.116 -0.032 0.177 -0.026 0.045 0.006 0.09 0.026 0.132 0.052 0.077 0.052 0.125 0.132 0.138 0.222 0.006 0.016 0.006 0.035 0.006 0.052'
      />
      <path
        d='M11.105 6.046c0 0.794 -1.511 1.437 -3.376 1.437 -1.865 0 -3.376 -0.643 -3.376 -1.437 0 -0.794 1.511 -1.438 3.376 -1.438 1.864 0 3.376 0.644 3.376 1.438'
      />
    </g>
  </svg>
)
export default IconCoins
