import { useQuery } from "@graphcommerce/graphql";
import { Image } from "@graphcommerce/image";
import Link from "next/link";
import { SocialMediaDocument } from "../../graphql/socialMedia/SocialMedia.gql";


// eslint-disable-next-line import/no-default-export
export default function SocialMediaList() {
  const social = useQuery(SocialMediaDocument);
  const items = social?.data?.socialMedia;

  if (Object.keys(items ?? {}).length === 0)
    return null;
  return (<div className="flex gap-4">
    {items && Object?.keys(items)?.filter(i => i !== '__typename' && items[i]?.link !== null && items[i]?.link !== null && items[i]?.logo !== null)?.map(item =>

      items[item]?.link && <Link className="rounded-full bg-light-170 w-6 h-6 flex items-center justify-center cursor-pointer" href={items[item]?.link}>
        <Image src={items[item]?.logo} width={18} height={18} className="text-default-100 rounded-full" />
      </Link>
    )}

  </div>)
}