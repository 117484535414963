import { Alert, Snackbar, SnackbarOrigin } from '@mui/material'
import React, { useEffect } from 'react';


export type CustomSnackbarType = {
  message: string;
  onClose?: () => void;
  severity?: "error" | "warning" | "info" | "success"
}
interface State extends SnackbarOrigin {
  open: boolean;

}

export default function CustomSnackbar(props: CustomSnackbarType) {
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });

  const { vertical, horizontal } = state;
  const { onClose, message, severity } = props;

  const handleClose = () => {
    if (onClose) onClose();
    setState({ ...state, open: false });
  };


  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open
      autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose}
        severity={severity ?? "success"}
        className='flex flex-row items-center '
        sx={{ width: '100%', direction: 'ltr' }}>
        {message}
      </Alert>
    </Snackbar>
  )

}