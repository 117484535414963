
import React, { useState } from "react";

import SearchBox from '../DevLayout/SearchBox'




function Search() {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className='w-full max-w-[600px] '>
      <div className={`max-w-[570px] h-[40px] flex flex-row items-center  m-auto ${open && 'hidden'}`}
        onClick={(e) => {
          setOpen(true)
        }} >
        <SearchBox />
      </div>
      {open &&
        <div className='fixed  justify-center top-0 left-0 right-0 bottom-0 bg-[#000000b3] z-[9990]' onClick={() => setOpen(false)}>
          <div className='m-auto mt-12 items-center w-auto'
            onClick={e => e.stopPropagation()}>
            <SearchBox open={open} setOpen={setOpen}/>
          </div>
        </div>
      }

    </div>  
  )
}



// eslint-disable-next-line import/no-default-export
export default Search

