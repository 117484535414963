import axios from "../axios";


export const getAdsBannerQuery = () => `{
  topNavStaticMessages
}`


export async function getAdsBanner() {
  return axios
    .post("/graphql", { query: getAdsBannerQuery() })
    .then((res) => res?.data)
    .catch((err) => {
      console.log('getStaticBlockData=====================>', err)
      return []
    });
}

