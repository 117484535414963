import { Fragment, useEffect, useState } from "react";
import useAdsBanner from "../../customHooks/useAdsBanner";


export default function AdsBanner() {
  const [showItem, setShowItem] = useState(0);
  const { text } = useAdsBanner();
 const [animating, setAnimating] = useState(false);
 
 useEffect(() => {
    if (text && text.length > 0) {

      const interval = setInterval(() => {
        setAnimating(true);
        setTimeout(() => {
          setAnimating(false);
          setShowItem((prev) => (prev > text.length - 2 ? 0 : prev + 1));
        }, 1000); 
      }, 6000); 

      return () => clearInterval(interval);
    }
  }, [text]);

  return (
    <div className="w-full mt-0 bg-[url('/headerimg.png')] flex justify-center items-center h-11 992:h-10 translate-y-[50px] 992:translate-y-[0px] z-0 992:border-x-0 max-992:m-0">
      <div className="max-w-970 1170:max-w-1170 text-center">
        {text?.slice(0,3)?.map((item, index) => (
          <Fragment key={item}>
            {index === showItem ? (
              <span className={`text-black text-sm font-medium ${animating ? "animate-fadeOut" : "animate-fadeIn"}`}>
                {item}
              </span>
            ) : null}
          </Fragment>
        ))}
      </div>
    </div>
  );
}