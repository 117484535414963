import { useRouter } from "next/router";
import BottomFooterSection from "./BottomFooterSection";
import CopyRightSection from "./CopyRightSection";
import MiddleFooterSection from "./MiddleFooterSection";
import MobileFooter from "./MobileFooter";
import TopFooterSection from "./TopFooterSection";

export default function () {
  const router = useRouter();
  const isPlp = ['/[...url]','/c/[...url]','/search/[...url]','/brands/[...url]'].includes(router.pathname)
  const isPdp = router.pathname == '/p/[url]'
  return (
    <footer className={`bg-[#363636] pt-7 ${isPlp?'max-992:mb-12':''} ${isPdp?'max-992:mb-24':''}`}>

      <MobileFooter />

      <div className="text-light-200 pt-7 hidden md:block">
        <TopFooterSection />
        
        <MiddleFooterSection />

        <BottomFooterSection />

        <CopyRightSection />
      </div>

    </footer>
  )
}