import { i18n } from "@lingui/core";
import { useContext } from 'react'
import { ModalContext } from '../../../context/context';
import { useWishlist } from "../../../context/wishlistContext";

import IconHeart from "../../icons/IconHeart";
import Loading from "../../icons/Loading";
import WishlistPage from "../../showWishList";
import useCustomerData from "../../../customHooks/useCustomerData";

// eslint-disable-next-line import/no-default-export
export default function WishListIcon() {
  const useModal = useContext(ModalContext);
  const { items, wishlistLoading, totalCount } = useWishlist()
  const { customerIsLogin } = useCustomerData()
  const loggedIn = customerIsLogin()

  return (
    <div>
      <div className="relative"
        onClick={() =>
          useModal.set && useModal.set({ title: i18n._('My favorite products'), content: <WishlistPage items={items} onClose={() => useModal.close && useModal.close} />, open: true, position: 'right' })}
      >

        <IconHeart width={30} height={28} className="text-ordme-gray-400/70 cursor-pointer" />

        {wishlistLoading && <span className="absolute w-4 h-4 text-sm leading-[1.2] text-center -top-2 -left-2  rounded-full"><Loading width={12} height={12} className="text-primary-500" /></span>}


        {!wishlistLoading && <span className="absolute w-4 h-4 text-sm leading-[1.2] text-center -top-2 -left-2 bg-error-500 rounded-full text-light-100">{loggedIn ? totalCount : 0}</span>}
      </div>
    </div >
  )
}
