function IconIconMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <style>
        {`.line { stroke: #555555; stroke-width: 1; stroke-linecap: round; }`}
      </style>
      <line x1="3" y1="9" x2="21" y2="9" className="line" />
      <line x1="3" y1="12" x2="21" y2="12" className="line" />
      <line x1="3" y1="15" x2="21" y2="15" className="line" />
    </svg>
  );
}

export default IconIconMenu;
