
import { useCompareSummary } from "@graphcommerce/magento-compare";
import { i18n } from "@lingui/core";
import { useContext } from "react";
import { ModalContext } from "../../../context/context";
import ProductCompareModal from "../../ProductCompareModal";
import IconStatsChartSharp from "../../icons/IconStatsChartSharp";

// eslint-disable-next-line import/no-default-export
export default function CompareIcon() {
  const useModal = useContext(ModalContext);
  const compareList = useCompareSummary();
  const length = compareList.data?.compareList?.items?.length;

  return (
    <div className="cursor-pointer">
      <div className="relative"
        onClick={() => useModal.set && useModal.set({ title: i18n._('List of selected products for comparison'), content: <ProductCompareModal />, open: true, position: 'right' })}>
        <IconStatsChartSharp
          width={35} height={20} className="text-ordme-gray-400/70" />

        {length ? <span className="absolute w-4 h-4 text-sm leading-[1.2] text-center -top-[10px] left-0 bg-error-500 rounded-full text-light-100">{length}</span>
          :
          <span className="absolute w-4 h-4 text-sm leading-[1.2] text-center -top-[10px] left-0 bg-error-500 rounded-full text-light-100">{0}</span>}
      </div>
    </div>
  )
}