import { useEffect, useState } from "react";
import { getAdsBanner } from "../api/getStoreConfigs/getAdsBanner";




function useAdsBanner() {
  const [text, setText] = useState<string[]>([]);

  useEffect(() => {

    async function fetchUrl() {
      const result = await getAdsBanner()
      setText(result?.data?.topNavStaticMessages as string[])
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchUrl()
  }, [])

  return ({
    text
  })

}
// eslint-disable-next-line import/no-default-export
export default useAdsBanner;