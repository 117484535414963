import Link from 'next/link'
import { useContext } from 'react'
import { ModalContext } from '../../../context/context'
import useCustomerData from '../../../customHooks/useCustomerData'
import AuthForms, { FormType } from '../../AuthForms'
import IconCheckLg from '../../icons/IconCheckLg'
import IconUser_male from '../../icons/IconUser_male'
import IconHeart from '../../icons/IconHeart'
import { Trans } from '@lingui/react'
import { SignOutForm } from '../../AuthForms/SignOutForm'
import IconCart3 from '../../icons/IconCart3'
import IconLogout from '../../icons/IconLogout'
import IconCoins from '../../icons/IconCoins'

function ProfileIcon() {
  const { customerIsLogin } = useCustomerData()
  const useModal = useContext(ModalContext)
  
  const accountModal = (
    <div>
      <a href="/account" className='flex flex-row-reverse justify-end items-center gap-4 mb-[1px]'>
        <span className='text-xs text-[#272727]'><Trans id='Account'/></span>
        <span className='bg-[rgba(178,11,81,0.6)] w-16 h-16 flex items-center justify-center'>
          <IconUser_male className=' text-white' width={25} height={25} />
        </span>
      </a>
      <a href="/account/orders" className='flex flex-row-reverse justify-end items-center gap-4 mb-[1px]'>
        <span className='text-xs text-[#272727]'><Trans id='My orders'/></span>
        <span className='bg-[rgba(178,11,81,0.7)] w-16 h-16 flex items-center justify-center'>
          <IconCart3  className=' text-white' width={20} height={20} />
        </span>
      </a>
      <a href="/wishlist" className='flex flex-row-reverse justify-end items-center gap-4 mb-[1px]'>
        <span className='text-xs text-[#272727]'><Trans id='My favorite goods'/></span>
        <span className='bg-[rgba(178,11,81,0.8)] w-16 h-16 flex items-center justify-center'>
          <IconHeart className=' text-white' width={24} height={24} />
        </span>
      </a>
      <a href="#" className='flex flex-row-reverse justify-end items-center gap-4 mb-[1px]'>
        <span className='text-xs text-[#272727]'><Trans id='Cooperation in sales'/><span className='text-[#847f7f]'>(<Trans id='cessation of activity'/>)</span></span>
        <span className='bg-[rgba(178,11,81,0.9)] w-16 h-16 flex items-center justify-center'>
          <IconCoins className=' text-white' width={20} height={20} />
        </span>
      </a>
      <SignOutForm>
        <button type='submit' className='flex flex-row-reverse justify-end items-center gap-4 w-full'>
          <span className='text-xs text-[#272727]'><Trans id='Sign out'/></span>
          <span className='bg-[rgb(178,11,81)] w-16 h-16 flex items-center justify-center'>
            <IconLogout className=' text-white' width={20} height={20} />
          </span>
        </button>
      </SignOutForm>
    </div>
  )

  const handleClick = (e) => {
    const isLargeScreen = window.innerWidth > 992
    if (isLargeScreen) {
      if (!customerIsLogin()) {
        e.preventDefault()
        if (useModal?.set) {
          useModal.set({
            content: <AuthForms activeForm={FormType.login} />,
            open: true,
            title: '',
            position: 'center',
            width: 'max-w-sm h-[380px]',
          });
        }
      } else {
        return
      }
    } else {
      e.preventDefault();
      if (!customerIsLogin() && useModal?.set) {
        useModal.set({
          content: <AuthForms activeForm={FormType.login} />,
          open: true,
          title: '',
          position: 'center',
          width: 'max-md:!size-full md:!w-96',
        })
      } else if (customerIsLogin() && useModal?.set) {
        useModal.set({
          content: accountModal,
          open: true,
          title: '',
          position: 'center',
          width: ' max-w-[40rem] h-auto !p-0 overflow-hidden m-2 992:hidden',
        })
      }
    }
  }

  return (
    <Link
      href={customerIsLogin() ? '/account' : ''}
      onClick={handleClick}
    >
      <div className='relative'>
        <IconUser_male className='text-ordme-gray-400/70' width={33} height={40} />

        {customerIsLogin() ? (
          <span className='absolute text-sm leading-[1.2] w-4 h-4 text-center rounded-full bg-ordme-purple text-white top-0 left-0 max-992:top-auto max-992:left-auto max-992:bottom-0 max-992:right-0 max-992:bg-white max-992:text-green-500 max-992:border max-992:border-green-500'>
            <IconCheckLg />
          </span>
        ) : null}
      </div>
    </Link>
  )
}
export default ProfileIcon
