
import { magentoMenuToNavigation } from '@graphcommerce/magento-category'
import {
  LayoutDefaultProps,
  DarkLightModeMenuSecondaryItem,
  NavigationProvider,
  useNavigationSelection,
  useMemoDeep,
} from '@graphcommerce/next-ui'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Divider } from '@mui/material'

import { useRouter } from 'next/router'
import { useContext } from 'react'
import { ModalContext } from '../../context/context'
import { storeLogoQuery } from '../../graphql/storeLogo.gql'

import RightModal, { CenterModal } from '../customLayout/rightModal'
import AdsBanner from './AdsBanner'
import BottomNavbar from './BottomNavbar'
import FooterSection from './FooterSection'
import { LayoutQuery } from './Layout.gql'
import { LayoutDefault } from './LayoutDefault'
import MiddleNavbar from './MiddleNavbar'
import MobileNavbar from './MobileNavbar'
import { SearchLink } from './SearchLink'
import TopNavbar from './TopNavbar'
import { MegaMenuContentQuery } from '../../graphql/MegaMenuContent.gql'

export type DevLayoutNavigationProps = storeLogoQuery & MegaMenuContentQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function DevLayoutNavigation(props: DevLayoutNavigationProps) {
  const { amMegaMenuTree:menu, storeLogo, children, ...uiProps } = props

  const selection = useNavigationSelection()
  const router = useRouter()
  const useModal = useContext(ModalContext);


  return (
    <NavigationProvider
      selection={selection}
      items={useMemoDeep(
        () => [
          <SearchLink
            href='/search'
            onClose={() => selection.set(false)}
            sx={(theme) => ({
              width: `calc(100% - ${theme.spacing(4)})`,
              m: 2,
              mb: theme.spacings.xs,
            })}
            aria-label={i18n._(/* i18n */ 'Search...')}
          >
            <Trans id='Search...' />
          </SearchLink>,

          {
            id: 'manual-item-one',
            href: `/${menu?.items?.[0]?.children?.[0]?.url_path}`,
            name: menu?.items?.[0]?.children?.[0]?.name ?? '',
          },
          {
            id: 'manual-item-two',
            href: `/${menu?.items?.[0]?.children?.[1]?.url_path}`,
            name: menu?.items?.[0]?.children?.[1]?.name ?? '',
          },
          ...magentoMenuToNavigation(menu, true),

          <Divider sx={(theme) => ({ my: theme.spacings.xs })} />,

          <DarkLightModeMenuSecondaryItem key='darkmode' />,
        ],
        [menu, selection],
      )}
    >

      <LayoutDefault
        {...uiProps}
        noSticky={router.asPath.split('?')[0] === '/'}
        header={
          <div className='mt-0 992:mt-6 w-full bg-red-300'>

            <CenterModal onClose={useModal.close} modalTitle={useModal.title} open={useModal.open && useModal.position === 'center'} width={useModal.width}>
              {useModal.content}
            </CenterModal>
            <RightModal onClose={useModal.close} modalTitle={useModal.title} open={useModal.open && useModal.position === 'right'} >
              {useModal.content}
            </RightModal>
            <TopNavbar />

            <AdsBanner />

            <MiddleNavbar storeLogo={storeLogo} />

            <BottomNavbar amMegaMenuTree={menu} storeLogo={storeLogo} />

            <MobileNavbar storeLogo={storeLogo} />
          </div>
        }
        footer={<FooterSection />}

      >
        {children}
      </LayoutDefault>
    </NavigationProvider>
  )
}
