import Link from "next/link";
import IconSearch from "../../icons/IconSearch";
import SearchBox from "../SearchBox";
import { useEffect, useRef, useState } from "react";

export default function SearchIcon() {
  const wrapperRef = useRef(null);
  const wrapperRef2 = useRef(null);
  const [open, setOpen] = useState<boolean>(false)


  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && wrapperRef2.current && !wrapperRef2.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  return (
    <>
      <div className="relative 992:hidden h-4" ref={wrapperRef2}>
        <Link href={"javascript:void(0)"} onClick={() => setOpen(true)}>

          <IconSearch width={24} height={24} className="text-light-400" style={{ transform: "translate(3px, -4px)" }} />
        </Link>
      </div>

      {open && <div className='fixed  justify-center top-0 left-0 right-0 bottom-0 bg-[#000000b3] z-[999999999999]' >
        <div className='m-auto mt-12 items-center w-auto' ref={wrapperRef}>
          <SearchBox open={open} setOpen={setOpen}/>


        </div>
      </div>}
    </>
  )
}